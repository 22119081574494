export default class ConnectionTypes {
  constructor() {
      this.items = [
          {
              "id": 1,
              "label": "enum.connection_types.mysql",
              "color": "rgba(242, 145, 17, 0.5)",
              "fields": [
                {
                  "name": "username",
                  "required": true,
                },
                {
                  "name": "password",
                  "required": false
                },
                {
                  "name": "database",
                  "required": false
                },
                {
                  "name": "host",
                  "required": false
                },
                {
                  "name": "port",
                  "required": false
                },
                {
                  "name": "ssl",
                  "required": false,
                  "type": "switch"
                },
                {
                  "name": "certificate",
                  "required": false
                },
              ]
          },
          {
              "id": 2,
              "label": "enum.connection_types.postgressql",
              "color": "rgba(45, 104, 143, 0.5)",
              "fields": [
                {
                  "name": "username",
                  "required": false
                },
                {
                  "name": "password",
                  "required": false
                },
                {
                  "name": "database",
                  "required": false
                },
                {
                  "name": "host",
                  "required": false
                },
                {
                  "name": "port",
                  "required": false
                },
                {
                  "name": "ssl",
                  "required": false
                },
                {
                  "name": "certificate",
                  "required": false
                },
              ]
          },
          {
              "id": 3,
              "label": "enum.connection_types.mongo_db",
              "color": "rgba(79, 170, 65, 0.5)",
              "fields": [
                {
                  "name": "database",
                  "required": false
                },
                {
                  "name": "username",
                  "required": false
                },
                {
                  "name": "password",
                  "required": false
                },
                {
                  "name": "collection",
                  "required": false
                },
                {
                  "name": "host",
                  "required": false
                },
                {
                  "name": "port",
                  "required": false
                },
                {
                  "name": "url",
                  "required": false
                },
                {
                  "name": "certificate",
                  "required": false
                },
              ]
          },
          {
              "id": 4,
              "label": "enum.connection_types.ftp",
              "color": "rgba(123, 26, 179, 0.5)",
              "fields": [
                {
                  "name": "username",
                  "required":  false
                },
                {
                  "name": "path",
                  "required": false
                },
                {
                  "name": "password",
                  "required": false
                },
                {
                  "name": "host",
                  "required": false
                },
                
              ]
          },
          {
              "id": 5,
              "label": "enum.connection_types.firebird",
              "color": "rgba(249, 119, 73, 0.5)",
              "fields": [
                {
                  "name": "host",
                  "required": false
                },
                {
                  "name": "usert",
                  "required":  false
                },
                {
                  "name": "password",
                  "required": false
                },
                
              ]
          },
      ];
  }
}