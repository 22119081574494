<template>
  <div>
      <b-collapse :visible="!!data" lazy>
        <template v-if="data">
          <div class="d-flex justify-content-between align-items-end mx-2">
            <div>
              <h5 class="mb-25 text-dark">Connection Info:</h5>
            </div>
            <div>
              <b-button variant="none" class="py-0 px-1" @clizck="$emit('closingInfo')">
                <b-icon icon="dash" scale="1.4"/>
              </b-button>
            </div>
          </div>
          <b-card class="mx-2">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h2 class="mx-50">
                  <template v-if="!editingName">
                    <span>{{data.name}}</span>
                    <b-button variant="none" class="py-0 px-50" @click="editingName = true">
                      <b-icon icon="pencil" scale="0.8"/>
                    </b-button>
                  </template>

                  <template v-else>
                    <div class="d-flex align-items-center">
                      <b-form-input v-model="data.name"></b-form-input>

                      <template v-if="loadingFields.includes('name')">
                        <b-spinner variant="info" small class="ml-1"/>
                      </template>

                      <template v-else>
                        <div class="d-flex">
                          <b-button variant="none" class="py-0 px-50 text-success d-flex align-items-center" @click="commitChangedName()">
                          <b-icon icon="check2" scale="0.8" class="mr-25"/> save 
                          </b-button>
                        </div>                      
                      </template>
                    </div>
                  </template>


                </h2>
              </div>
              <div>
                <h4> 
                  <span class="mr-1">Type:</span>
                  <b-badge variant="secondary" :style="`background-color: ${getTypeByID(data.enum_connection_type_id).color};`">
                    <span class="text-light">{{$t(getTypeByID(data.enum_connection_type_id).label)}}</span>
                  </b-badge>
                </h4>
              </div>
            </div>

            <!-- ========================= Credentials ==========================  -->
            
            <div class="d-flex justify-content-center">
              <div class="d-flex align-items-center w-50 mb-1">
                <hr class="w-25">
                <span class="font-weight-bolder">CREDENTIALS</span>
                <hr class="w-25">
              </div>
            </div>
            
              <my-sql-credentials :data="data" :key="'credential-' + reactiveKey" v-if="data.enum_connection_type_id == 1" />
              <postgres-sql-credentials :data="data" :key="'credential-' + reactiveKey" v-if="data.enum_connection_type_id == 2"/>
              <mongo-db-credentials :data="data" :key="'credential-' + reactiveKey" v-if="data.enum_connection_type_id == 3"/>
              <ftp-credentials :data="data" :key="'credential-' + reactiveKey" v-if="data.enum_connection_type_id == 4"/>
              <firebird-credentials :data="data" :key="'credential-' + reactiveKey" v-if="data.enum_connection_type_id == 5"/>

            <!-- ========================= Credentials ==========================  -->

            <div class="mx-1 mt-1 d-flex align-items-center">
              <span class="font-weight-bolder">Status:</span>

              <template v-if="!editingFields.includes('status')">
                <div class="">
                  <b-badge :variant="getEnumStatus(data.enum_status_id).variant" class="ml-1">{{getEnumStatus(data.enum_status_id).label}}</b-badge>
                
                  <b-button class="p-0 ml-50" variant="none" @click="editingFields.push('status')">
                    <b-icon icon="pencil" scale="0.8"/>
                  </b-button>
                </div>
              </template>

              <template v-else> 

                <div class="w-75 d-flex align-items-center">
                  <v-select class="w-25 ml-1" :options="getEnumStatus()" :clearable="false" :reduce="item => item.id" v-model="data.enum_status_id">
                    <template #option="data">
                      <div align="center">
                        <b-badge :variant="data.variant" class="w-100">{{data.label}}</b-badge>
                      </div>
                    </template>

                    <template #selected-option="data">
                      <b-badge :variant="data.variant" class="w-100">{{data.label}}</b-badge>
                    </template>
                  </v-select>
                  

                  <b-button class="p-0 ml-50 text-success" variant="none" @click="saveStatus()">
                    <b-icon icon="check2" scale="0.8"/> save
                  </b-button>

                </div>
              </template>

            </div>
            <div align="right" class="mt-2">
                <b-button variant="outline-danger" class="py-50 px-50" @click="$emit('openDeleteModal', data)" >
                  <span class="">
                    <b-icon icon="trash"/> Delete Connection 
                  </span>
              </b-button>
            </div>
          </b-card>
        </template>
      </b-collapse>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ConnectionTypes from '@/custom/class/Enum/ConnectionTypes.js'
import createConnectionModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateConnectionModal.vue"
import connectionStatus from '@/custom/class/Enum/ConnectionStatus.js'
import { makeToast } from '@/layouts/components/Popups';
import VSelect from "vue-select";
import MySqlCredentials from "@/views/pages/flux_connections/MySqlCredentials.vue";
import PostgresSqlCredentials from "@/views/pages/flux_connections/PostgresSqlCredentials.vue"
import MongoDbCredentials from "@/views/pages/flux_connections/MongoDbCredentials.vue";
import FtpCredentials from "@/views/pages/flux_connections/FtpCredentials.vue";
import FirebirdCredentials from "@/views/pages/flux_connections/FirebirdCredentials.vue"

import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  BSpinner,
  BCollapse,
  BCard,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    createConnectionModal,
    BSpinner,
    BCollapse,
    BCard,
    BFormCheckbox,
    VSelect,
    MySqlCredentials,
    PostgresSqlCredentials,
    MongoDbCredentials,
    FtpCredentials,
    FirebirdCredentials,
  },
  data() {
    return {
      fieldsDictionary:{
        name: 'name',
        username: 'user',
        path: 'path',
        port: 'port',
        database: 'database',
        host: 'host',
      },
      editingFields: [],
      loadingFields: [],
      editingName: false,
      reactiveKey: 1
    }
  },
  props: {
    data: {
      type: Object,
      default: undefined 
    },
  },
  computed: {
    ...mapGetters('connection',['getConnections']),
    connections(){
      return this.getConnections
    },
    organizationID() {
      return localStorage.getItem('selectedOrganization')
    },
  },
  mounted () {
  },
  methods: {
    getTypeByID(id){
      let type = new ConnectionTypes().items.find(el=>el.id == id)
      return type
    },
    getEnumStatus(id = undefined){
      if (id){
        let item = new connectionStatus().items.find(el=>el.id == id)
        return item
      } else {
        return new connectionStatus().items
      }
    },
    openDeleteModal(item){
      this.$emit('openDeleteModal', item)
      return
    },
    saveFieldChange(field){

      this.loadingFields.push(field)
      
      {
        let idx = this.editingFields.findIndex(el=> el == field)
        if (idx > -1){
          this.editingFields.splice(idx, 1)
        }
      }

      let convField = this.fieldsDictionary[field]

      let payload = {
        [convField] : this.data[convField],
        organization: this.organizationID,
        connection: this.data.id
      }      

      this.$store.dispatch('connection/patchConnectionField', payload).then((resp)=>{
        makeToast({
          title: `Saved!`,
          text: `Connection successfully updated`,
          variant: 'success',
          icon: 'CheckIcon'
        })
      })
      .catch((err)=>{
        console.error(err)
        makeToast({
          title: `Error!`,
          text: `Connection couldn't be updated`,
          variant: 'danger',
          icon: 'XIcon'
        })
      })
      .finally(()=>{
        {
          let idx = this.loadingFields.findIndex(el=> el == field)
          if (idx > -1){
            this.loadingFields.splice(idx, 1)
          }
        }
      })

    },
    commitChangedName(){
      let newName = this.data.name
      this.loadingFields.push('name')

      let payload = {
        name: newName,
        organization: this.organizationID,
        connection: this.data.id
      }      

      this.$store.dispatch('connection/patchConnectionField', payload).then((resp)=>{
        makeToast({
          title: `Saved!`,
          text: `Connection successfully updated`,
          variant: 'success',
          icon: 'CheckIcon'
        })
        this.editingName = false
      })
      .catch((err)=>{
        console.error(err)
        makeToast({
          title: `Error!`,
          text: `Connection couldn't be updated`,
          variant: 'danger',
          icon: 'XIcon'
        })
      })
      .finally(()=>{
        let idx = this.loadingFields.findIndex(el => el == 'name')
        if (idx > -1){
          this.loadingFields.splice(idx, 1)
        }
      })
    },
    saveStatus(){

      let newStatus = this.data.enum_status_id                                                         

      this.loadingFields.push('status')

      let payload = {
        enum_status: newStatus,
        organization: this.organizationID,
        connection: this.data.id
      }      

      this.$store.dispatch('connection/patchConnectionField', payload).then((resp)=>{
        makeToast({
          title: `Saved!`,
          text: `Connection successfully updated`,
          variant: 'success',
          icon: 'CheckIcon'
        })
      })
      .catch((err)=>{
        console.error(err)
        makeToast({
          title: `Error!`,
          text: `Connection couldn't be updated`,
          variant: 'danger',
          icon: 'XIcon'
        })
      })
      .finally(()=>{
        let idx = this.loadingFields.findIndex(el => el == 'status')
        if (idx > -1){
          this.loadingFields.splice(idx, 1)
        }

        idx = this.editingFields.findIndex(el => el == 'status')
        if (idx > -1){
          this.editingFields.splice(idx, 1)
        }
      })
    },
    reset(){
      this.editingFields = []
      this.loadingFields = []
      this.editingName = false
      this.reactiveKey = this.reactiveKey + 1
    }
  }
};
</script>

<style>

.w-250px{
  width: 250px;
}

</style>