<template>
  <div>
    <b-modal
      centered
      ref="create-connection-modal"
      @hide="((e)=>{ if (modalInfo.loading) e.preventDefault()})"
      size="lg"
      noCloseOnBackdrop
    >
    
      <template #modal-title>
        <div>
          <b-icon icon="globe2" class="mr-25"/>
          Creating Connection
        </div>
      </template>

      <b-row no-gutters class="mt-50">
        <b-col cols="9" class="pr-2">
          <span class="font-weight-bold">Connection Name:</span>
          <b-form-input trim :state="inputStates.name" v-model="connectionInfo.name" @input="inputStates.name = undefined"></b-form-input>
        </b-col>
        <b-col cols="3">
          <span class="font-weight-bold">Type:</span>
          <span :class="inputStates.type == false ? 'state-false-select' : ''">

            <v-select :options="getConnectionTypes()" v-model="connectionInfo.type" :clearable="false" @input="clearConnectionData(); inputStates.type = undefined;">
              
              <template #selected-option="data" v-model="connectionInfo.type">
                <span class="font-weight-bolder">{{$t(data.label)}}</span>
              </template>

              <template #option="data" v-model="connectionInfo.type" >
                <span class="font-weight-bolder">{{$t(data.label)}}</span>
              </template>
            </v-select>
          </span>
        </b-col>
      </b-row>

      <div class="mt-2 mb-1 set-min-height">
        <b-collapse :visible="true" appear lazy v-if="connectionInfo.type">
          
          <div class="d-flex justify-content-center align-items-center">
            <hr class="w-25">
            <span class="mx-1 font-weight-bolder">CREDENTIALS:</span>
            <hr class="w-25">
          </div>
          
          <my-sql-credentials :data="connectionInfo" :editFieldsIndividually="false" v-if="connectionInfo.type.id == 1" ref="connection-credentials"/>
          <postgres-sql-credentials :data="connectionInfo" :editFieldsIndividually="false" v-if="connectionInfo.type.id == 2" ref="connection-credentials"/>
          <mongo-db-credentials :data="connectionInfo" :editFieldsIndividually="false" v-if="connectionInfo.type.id == 3" ref="connection-credentials"/>
          <ftp-credentials :data="connectionInfo" :editFieldsIndividually="false" v-if="connectionInfo.type.id == 4" ref="connection-credentials"/>
          <firebird-credentials :data="connectionInfo" :editFieldsIndividually="false" v-if="connectionInfo.type.id == 5" ref="connection-credentials"/>

        </b-collapse>
      </div>
      
      <template #modal-footer>
        <div>
          <!-- banana -->
          <!-- <b-button variant="outline-info" class="mr-1 px-50" @click="" :disabled="true">
            <span v-if="!modalInfo.loading" class="font-weight-bolder">
              Test Connection <b-icon icon="play" scale="1.2"/>
            </span>
          </b-button> -->
          <!-- banana -->

          <b-button :disabled="modalInfo.loading" variant="success" @click="attemptSubmit()" >
            <span v-if="!modalInfo.loading">Submit</span>
            <b-spinner small v-else />
          </b-button>
        </div>
      </template>

      <!-- {{inputStates}} -->

    </b-modal>
  </div>
</template>

<script>
import { 
  BCol,
  BRow, 
  BButton, 
  BButtonGroup, 
  BSkeleton, 
  BModal, 
  BSpinner, 
  BFormInput, 
  BBadge, 
  BCollapse 
} from "bootstrap-vue";

import  VSelect from "vue-select";
import { makeToast } from "@/layouts/components/Popups";
import ConnectionTypes from '@/custom/class/Enum/ConnectionTypes.js'
import MySqlCredentials from "@/views/pages/flux_connections/MySqlCredentials.vue";
import PostgresSqlCredentials from '@/views/pages/flux_connections/PostgresSqlCredentials.vue'
import MongoDbCredentials from '@/views/pages/flux_connections/MongoDbCredentials.vue'
import FtpCredentials from "@/views/pages/flux_connections/FtpCredentials.vue";
import FirebirdCredentials from "@/views/pages/flux_connections/FirebirdCredentials.vue"

  export default {
    components: {
      BCol,
      BRow, 
      BButton, 
      BButtonGroup, 
      BSkeleton, 
      BModal, 
      BSpinner, 
      BFormInput, 
      BBadge, 
      BCollapse,
      VSelect,
      MySqlCredentials,
      PostgresSqlCredentials,
      MongoDbCredentials,
      FtpCredentials,
      FirebirdCredentials,
    },
    data() {
      return {
        modalInfo:{
          loading: false,
        },
        connectionInfo:{
          name: '',
          type: new ConnectionTypes().items[0]
        },
        inputStates:{
          name: undefined,  
          type: undefined
        }
      }
    },
    computed: {
      organizationID() {
        return localStorage.getItem('selectedOrganization')
      }
    },
    methods: {
      show(){
        this.$refs['create-connection-modal'].show()
      },
      getConnectionTypes(){
        return new ConnectionTypes().items
      },
      clearConnectionData(){
        this.connectionInfo = {
          name: this.connectionInfo.name,
          type: this.connectionInfo.type,
        }
      },
      attemptSubmit(){
        let inputsValid = this.$refs['connection-credentials'].areInputsValid()

        if (!this.connectionInfo.name || this.connectionInfo.name.length <= 0){
          inputsValid = false
          this.inputStates.name=  false
        }
        if (!inputsValid){
          return
        }

        this.submit()        
      },
      submit(){
        let data = structuredClone(this.connectionInfo)
        let type = data.type

        let payload = {}

        payload.enum_connection_type = type.id
        payload.organization = this.organizationID

        payload.name = data.name
        payload.database = data.connection_database
        payload.host = data.host
        payload.pass = data.pass
        payload.user = data.user
        payload.port = data.port
        payload.tls = data.tls ? String(data.tls) : 'disable'
        payload.auth_type = data.auth_type
        payload.auth_certificate = data.auth_certificate && data.auth_certificate.length > 0 ? data.auth_certificate : null ,
        payload.certificate = data.certificate ? String(data.certificate) : null
        
        this.$store.dispatch('connection/createConnection', payload).then((resp)=>{
          this.modalInfo.loading = false
          makeToast({
            title: `Success!`,
            text: `Connection created successfully`,
            variant: 'success',
            icon: 'CheckIcon'
          })

          this.$nextTick(()=>{
            this.$refs['create-connection-modal'].hide()
            this.connectionInfo = {
              name: '',
              type: new ConnectionTypes().items[0]
            }
          })
        })
        .catch((err)=>{
          console.error(err)
          makeToast({
            title: `Error!`,
            text: `Connection couldn't be created`,
            variant: 'danger',
            icon: 'XIcon'
          })
        })
        .finally(()=>{
          this.modalInfo.loading = false
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.state-false-select{
  outline: 1px solid var(--danger);
  border-radius: 4px !important;
  display: block;
}

.transform-height-animation{
  height: fit-content;
  max-height:fit-content;
  transition: all 1s;
}

.set-min-height{
  min-height: 320px;
  
}
</style>